@use '/src/common/Styles/colors';
@use '/src/common/Styles/font';


div.sponsor_search {
  color: colors.$colors-PrimaryDefault;

  :after {
    border-bottom: 2px solid colors.$colors-PrimaryDefault
  }
}

.stack_control {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.sort_button {
  transform: rotate(90deg);
}

.sort_title {
  color: colors.$colors-PrimaryDefault;
}